html,
body {
  height: 100%;
  width: 100%;

  * {
    font-family: "Jost", sans-serif;
    box-sizing: border-box;
  }
}
.visit-bp-page {
  height: 100%;
  background-color: #ecab3b;
  .hero {
    overflow: hidden;
    .hero-img {
      height: 100vh;
      background-image: url(../imgs/hero.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(rgba(236, 171, 59, 0.8) 90%, rgba(236, 171, 59, 1));
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 0;
      }
      h1 {
        position: absolute;
        color: white;
        z-index: 2;
        bottom: 100px;
        width: 100%;
        max-width: 400px;
        font-size: 3rem;
        line-height: 4rem;
        padding-left: 20px;
        * {
          display: block;
        }
        // .first {
        //   line-height: 80px;
        //   padding-left: 20px;
        // }
        // .second {
        //   font-size: 2.5rem;
        //   text-align: right;
        //   line-height: 40px;
        //   padding-right: 40px;
        // }
        // .third {
        //   padding-left: 40px;
        //   font-size: 3rem;
        // }
        @media (min-width: 600px) {
          bottom: 150px;
        }
        @media (min-width: 1000px) {
          bottom: 300px;
          left: 50px;
        }
        @media (min-width: 1200px) {
          max-width: fit-content;
          left: 0;
          font-size: 5rem;
          line-height: 5.5rem;
          padding-left: 100px;

          // .first {
          //   font-size: 8rem;
          //   line-height: 140px;
          //   padding-left: 50px;
          // }
          // .second {
          //   font-size: 4rem;
          //   text-align: right;
          //   line-height: 40px;
          //   padding-right: 20px;
          // }
          // .third {
          //   padding-left: 40px;
          //   font-size: 6rem;
          // }
        }
        @media (min-width: 1500px) {
          left: 100px;
          bottom: 250px;
        }
      }
    }
    .small-img {
      width: 90%;
      height: 100%;
      position: absolute;
      right: -100px;
      z-index: 1;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(../imgs/414x.png);
      max-width: 1000px;
      @media (min-width: 600px) {
        right: -50px;
        //right: 0;
        width: 80%;
        background-image: url(../imgs/800x.png);
      }
      @media (min-width: 1000px) {
        right: 0;
        background-image: url(../imgs/1920x.png);
        width: 70%;
      }
    }
    .header {
      width: 320px;
      height: 50px;
      background-color: #143551;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      z-index: 5;
      left: 50%;
      transform: translate(-50%);
      top: 50px;
      @media (min-width: 1000px) {
        transform: translate(0);
        left: 0;
      }
      .logo {
        height: 100%;
        margin-left: 10px;
      }
      color: white;
      font-size: 1.5rem;
      font-weight: 600;
      .hamburger {
        width: 50px;
        height: 100%;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        justify-content: center;
        gap: 6px;
        background-color: #b83b3b;
        .line {
          height: 4px;
          width: 30px;
          margin-left: 10px;
          background-color: white;
          &.short {
            width: 15px;
            justify-self: flex-start;
          }
        }
      }
    }
    .hero-arrow {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      z-index: 2;
      cursor: pointer;
      .arrow-top {
        width: 5px;
        height: 10px;
        background-color: #b83b3b;
        border-radius: 3px;
      }
      color: #b83b3b;
      .arrow-btm {
        width: 5px;
        height: 20px;
        background-color: #b83b3b;
        border-radius: 3px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 5px;
          left: -5px;
          border-left: 5px solid #b83b3b;
          border-bottom: 5px solid #b83b3b;
          width: 10px;
          height: 10px;
          transform: rotate(-45deg);
          border-radius: 3px;
        }
      }
    }
  }
  .lang-selector {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    background-color: #143551;
    padding: 20px;
    min-width: 320px;
    max-width: 360px;
    box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.5);
    .close {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 15px;
      right: 20px;
      cursor: pointer;
      &::after {
        content: "";
        position: absolute;
        top: 7px;
        right: 0;
        border-bottom: 2px solid white;
        transform: rotate(-45deg);
        width: 20px;
      }
      &::before {
        border-bottom: 2px solid white;
        content: "";
        transform: rotate(45deg);
        width: 20px;
        position: absolute;
        top: 7px;
        right: 0;
      }
    }
    p {
      margin-bottom: 30px;
      text-align: center;
      font-size: 1.3rem;
      text-transform: uppercase;
      color: white;
    }
    .lang-item {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      align-items: center;
      padding: 15px 0;
      cursor: pointer;
      .lang {
        font-size: 1.1rem;
        color: white;
      }
      .flag {
        width: 40px;
        height: 26px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        justify-self: center;
        box-shadow: 1px 1px 6px 3px rgba(128, 128, 128, 0.2);
        &.--hun {
          background-image: url(../imgs/flags/hun.svg);
        }
        &.--rom {
          background-image: url(../imgs/flags/rom.png);
        }
        &.--eng {
          background-image: url(../imgs/flags/eng.svg);
        }
        &.--rus {
          background-image: url(../imgs/flags/rus.svg);
        }
        &.--chi {
          background-image: url(../imgs/flags/chi.svg);
        }
        &.--ita {
          background-image: url(../imgs/flags/ita.svg);
        }
        &.--fra {
          background-image: url(../imgs/flags/fra.svg);
        }
        &.--spa {
          background-image: url(../imgs/flags/spa.svg);
        }
        &.--ger {
          background-image: url(../imgs/flags/ger.svg);
        }
      }
    }
    &.hidden {
      display: none;
    }
  }
  .guide-wrap {
    width: 100%;
    background-color: #ecab3b;

    min-height: 500px;
    display: flex;
    align-items: center;
    @media (min-width: 450px) {
      justify-content: center;
    }

    .guide {
      position: relative;
      max-width: 420px;
      min-width: 320px;
      min-height: 300px;
      @media (min-width: 600px) {
        max-width: 500px;
      }
      .guide-body {
        .guide-head {
          position: relative;
          right: -40%;
          top: 0;
          background-color: #ecab3b;
          padding: 10px 0 10px 30px;
          border-radius: 20px 0 0 20px;
          max-width: 320px;
          z-index: 3;
          p {
            color: #843d22;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 1.2rem;
            span {
              color: white;
              text-transform: none;
              font-weight: 400;
            }
          }
          @media (max-width: 600px) {
            right: -20%;
          }
          @media (max-width: 400px) {
            right: -20px;
          }
        }
        background-color: #143551;
        position: relative;
        z-index: 2;
        top: 0;
        left: -20px;
        max-width: 360px;
        @media (min-width: 600px) {
          max-width: 500px;
        }
        border-radius: 20px;
        padding: 20px 20px 20px 50px;
        @media (min-width: 450px) {
          left: 0;
        }
        p {
          color: white;
          font-weight: 300;
        }
      }
      &::after {
        width: 350px;
        height: 50px;
        position: absolute;
        left: -20px;
        z-index: 1;
        bottom: -15px;
        content: "";
        background-color: #b83b3b;
        border-radius: 20px;
        @media (min-width: 450px) {
          left: 0;
        }
        @media (min-width: 600px) {
          width: 480px;
        }
      }
    }
  }
  .pois-wrap {
    background-color: #b83b3b;

    padding: 50px 20px;
    @media (min-width: 450px) {
      padding: 50px;
    }
    @media (min-width: 600px) {
      padding: 50px 100px;
    }
  }
  .sight-item {
    max-width: 1200px;
    margin: auto;
    display: grid;
    border-radius: 0 20px 0 20px;
    grid-template-columns: 100px 1fr 1fr;
    width: 100%;
    height: 100%;
    background-color: #ecab3b;
    position: relative;
    color: #5b2415;
    font-weight: 300;
    padding: 50px 0 0;
    .img-wrap {
      max-height: 500px;
      img {
        object-position: center center;
      }
      &.szab {
        img {
          object-position: 20% 50%;
        }
      }
      &.ship {
        img {
          object-position: 20% 100%;
          @media (max-width: 1000px) {
            object-position: 50% 100%;
          }
        }
      }
    }
    &.mobile {
      grid-template-columns: 1fr;
      margin-bottom: 50px;
      &:last-of-type {
        margin-bottom: 0;
      }
      svg {
        position: inherit;
        transform: translateY(5px);
      }
      padding: 0;
      .number {
        position: absolute;
        left: 0;
        top: 0;
        height: 75px;
        width: 75px;
        @media (max-width: 700px) {
          height: 50px;
          width: 50px;
          font-size: 2rem;
        }
      }
      .img-wrap {
        padding: 0 50px;
        max-height: 350px;

        @media (max-width: 700px) {
          padding: 20px;
          max-height: 250px;
        }
      }

      h2 {
        text-align: left;
        padding: 0 20px;
        color: #5b2415;
        margin-bottom: 50px;
        @media (max-width: 700px) {
          margin: 15px 0 0 0;
          margin-left: 50px;
          padding: 0 20px;
        }
        margin-left: 75px;
      }
      .text-wrap {
        padding: 20px 50px 0;
        //text-align: center;
        justify-self: center;
        color: #5b2415;
        @media (max-width: 700px) {
          padding: 0 20px 20px;
          p {
            margin: 0;
          }
        }
      }
    }
  }
  .ship-btn {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 0 15px;
    background-color: #b83b3b;
    border-radius: 16px;
    margin: 20px auto;
    cursor: pointer;
    a {
      color: white;
      margin: 10px 0;
      text-decoration: none;
    }
    img {
      width: 40px;
      height: 40px;
    }
    @media (min-width: 1000px) {
      margin: 20px 0;
    }
  }
  .img-wrap {
    height: 100%;
    width: 100%;
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .img-wrap,
  .text-wrap {
    z-index: 1;
    position: relative;
  }
  .text-wrap {
    max-width: 500px;
    padding-bottom: 75px;
    padding-left: 50px;
    padding-right: 50px;
  }

  svg {
    object-fit: cover;
    width: 100%;
    z-index: 0;
    position: absolute;
    top: 0;
    transform: translateY(-200px);
  }
  .bg-svg {
    background-color: #143551;
    padding: 20px 0;
    position: relative;
    z-index: 1;
    border-radius: 0 0 0 20px;
  }
  .number {
    color: white;
    font-size: 3rem;
    font-weight: 600;
    background-color: #143551;
    align-self: flex-start;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .location-wrap,
  .hours-wrap {
    padding: 0 20px;
    color: white;
    display: flex;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    .icon {
      background-color: #ecab3b;
      width: 40px;
      height: 40px;
      padding: 7px;
      border-radius: 12px;
    }
    .open,
    .loc {
      font-size: 0.8rem;
      margin: 0;
    }
    .place {
      margin: 0;
    }
  }

  .ship-section {
    background-color: #ecab3b;

    padding: 20px;
    @media (min-width: 450px) {
      padding: 50px;
    }
    @media (min-width: 600px) {
      padding: 50px 100px;
    }
    .ship-btn {
      background-color: #e6b154;
    }
    .sight-item {
      .number {
        background-color: #b83b3b;
      }
      background-color: #143551;
      * {
        color: white !important;
      }
    }
  }
  .sight-item-footer {
    max-width: 1200px;
    margin: 0 auto 50px;
    position: relative;
    .bg-svg {
      padding: 20px 130px 50px;
    }
    svg {
      @media (max-width: 1150px) {
        transform: translateY(-150px);
      }
    }
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .map-section {
    padding: 0 20px 50px 20px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    @media (min-width: 500px) {
      padding: 0 50px 50px;
    }
    @media (min-width: 700px) {
      padding: 0 100px 50px;
    }
    .map-wrap {
      position: relative;
      max-width: 900px;
    }
    img {
      width: 100%;

      object-fit: cover;
      max-width: 800px;
      margin: 0 auto;
    }

    h2 {
      color: #5b2415;
      font-size: 2rem;
      .second {
        padding-left: 100px;
        width: 100%;
        display: block;
      }
      @media (min-width: 1000px) {
        position: absolute;
        top: 50%;
        right: -50px;
        font-size: 2.2rem;
        .first {
          padding-left: 50px;
        }
        .second {
          padding: 0 0 0 20px;
        }
      }
      @media (min-width: 1200px) {
        right: -100px;
      }
    }
  }
  footer {
    background-color: #143551;
    padding: 50px;
    .logo-wrap {
      display: flex;
      justify-content: center;
      .logo {
        width: 50px;
        height: 50px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .brand-text-wrap {
        * {
          color: white !important;
          margin: 0;
        }
        .text {
          font-size: 0.8rem;
        }
      }
    }
    .partners {
      text-align: center;
      color: white;
      text-transform: uppercase;
      margin-top: 40px;
      font-weight: 300;
    }
  }
  .partners-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 50px;
    row-gap: 10px;
    margin: auto 0;
    @media (max-width: 450px) {
      flex-direction: column;
      gap: 10px;
    }
    a {
      text-decoration: none;
      color: white;
      display: inline-block;
      height: 100%;
      width: 200px;
      img {
        width: 100%;
      }
      @media (max-width: 850px) {
        width: 150px;
      }
    }
    .partner-icon {
      height: 60px;
      color: white;
      font-weight: 300;
      img {
        height: 100%;
        object-fit: contain;
      }
    }
    .kisf {
      padding: 5px 0;
    }
    .ss-bp {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      align-items: center;
      span {
        margin-top: -10px;
        font-size: 12px;
      }
      img {
        height: 50px;
        transform: scale(1.1, 1.1);
      }
    }
  }
}
footer {
  background-color: #143551;
  padding: 50px;
  .logo-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .logo {
      width: 200px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      @media (max-width: 850px) {
        width: 150px;
      }
    }
    .brand-text-wrap {
      * {
        color: white !important;
        margin: 0;
      }
      .text {
        font-size: 0.8rem;
      }
    }
  }
  .partners {
    text-align: center;
    color: white;
    text-transform: uppercase;
    margin-top: 40px;
    font-weight: 300;
  }
}
.partners-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 50px;
  row-gap: 10px;
  margin: auto 0;
  @media (max-width: 450px) {
    flex-direction: column;
    gap: 10px;
  }
  a {
    text-decoration: none;
    color: white;
    display: inline-block;
    height: 100%;
    max-width: 150px;
    img {
      width: 100%;
    }
  }
  .partner-icon {
    height: 50px;
    color: white;
    font-weight: 300;
    img {
      height: 100%;
      object-fit: contain;
    }
  }
  .kisf {
    padding: 5px 0;
  }
  .ss-bp {
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    align-items: center;
    span {
      margin-top: -10px;
      line-height: 12px;
      font-size: 12px;
      white-space: nowrap;
    }
    img {
      height: 45px;
      margin-top: -5px;
    }
  }
}
